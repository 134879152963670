import React from "react";
import '../styles/semantic.min.css';
import LayoutContainer from "../container/LayoutContainer";
import SEO from "../components/seo/Seo";
import MainNavigation from "../components/navigation/MainNavigation";
import Footer from "../components/footer/Footer";
import PageHeader from "../components/utils/PageHeader";
import {Container, Grid, Segment} from "semantic-ui-react";
import {graphql, StaticQuery} from "gatsby";
import AboutUsNavigation from "../components/navigation/AboutUsNavigation";
import MobileTabletContainer from "../container/MobileTabletContainer";
import DesktopContainer from "../container/DesktopContainer";
import styles from "../components/about-us/aboutUs.module.css";
import MobileBgWrapper from "../container/MobileBgWrapper";
import layout from "../templates/layout.module.css";

export default () => <LayoutContainer>
    <SEO title={'Über uns'}/>

        <StaticQuery query={aboutUsQuery}
                     render={
                         data => {
                             return (
                                 <>
                                     <MobileTabletContainer>
                                         <MainNavigation/>
                                         <PageHeader title={data.allMarkdownRemark.edges[0].node.frontmatter.title}
                                                     subHeader={data.allMarkdownRemark.edges[0].node.frontmatter.summary}/>
                                         <MobileBgWrapper>
                                             <Container>
                                             <Segment>
                                         <Container className={styles.container}
                                             dangerouslySetInnerHTML={{__html: data.allMarkdownRemark.edges[0].node.html}}>
                                         </Container>
                                             </Segment>
                                             </Container>
                                         </MobileBgWrapper>
                                         <Footer/>
                                     </MobileTabletContainer>
                                     <DesktopContainer>
                                         <div className={layout.pageWrapper}>
                                         <MainNavigation/>
                                         <Container fluid>
                                         <PageHeader title={data.allMarkdownRemark.edges[0].node.frontmatter.title}
                                                     subHeader={data.allMarkdownRemark.edges[0].node.frontmatter.summary}/>
                                         <Grid centered columns={2}>
                                             <Grid.Column width={11}>
                                                 <Segment>
                                                     <Container className={styles.container}
                                                                dangerouslySetInnerHTML={{__html: data.allMarkdownRemark.edges[0].node.html}}>
                                                     </Container>
                                                 </Segment>
                                                 <AboutUsNavigation/>
                                             </Grid.Column>
                                             <Grid.Column width={4}/>
                                         </Grid>
                                         </Container>
                                         <Footer/>
                                         </div>
                                     </DesktopContainer>
                                 </>
                             )
                         }
                     }
        />


</LayoutContainer>

const aboutUsQuery = graphql`
query aboutUsQuery {
    allMarkdownRemark( 
      filter:{
        frontmatter: {
            type: { eq: "about-us" }
            kind: { eq: "about-us" }
         }
      }
      limit: 1
      ){
      edges {
        node {
            html
            frontmatter {
                title
                summary
            }
        }
      }
    }
}
`;